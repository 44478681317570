import React, { Component } from "react";
import { Link } from "gatsby";

import Layout from "../layouts/en";
import SEO from "../components/seo";

export default class TermsAndConditionsPage extends Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    const specific_date = new Date("2022-01-09");
    const current_date = new Date();
    const url = this.props.location.href;
    const sectionUrl = url?.split("#")[1] ?? "";
    //scroll to section if have the sectionURL
    if (sectionUrl) {
      const element = document.getElementById(sectionUrl);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
    if (current_date.getTime() > specific_date.getTime()) {
      return (
        <Layout location={this.props.location}>
          <SEO
            title="Terms and Conditions "
            keyword="Terms and Conditions"
            decription="Terms and Conditions"
          />

          <div className="terms">
            <div className="container container--1000">
              <h1 className="text--center">Terms and Conditions</h1>
              <p className="p--sm">Updated October 2024</p>
              <p className="p--sm">
                Below are the revised terms and conditions that outline the
                contractual agreement between PT AYANA Hotel Manajemen (referred
                to as "the <span style={{ fontWeight: "700" }}>Company</span>"
                or "AYANA Hospitality") and members of the AYANA Rewards
                program. These updated terms and conditions will come into
                effect on October 1, 2024 (referred to as the "
                <span style={{ fontWeight: "700" }}>Effective Date</span>"),
                replacing the previous terms and conditions of the AYANA Rewards
                program. In addition to other changes, members with no activity
                for more than 2 years as of October 1, 2024 (calculated from
                September 30, 2022, and earlier), will have their status
                deactivated and points forfeited. Members with activity within 2
                years leading up to October 1, 2024, will have their active
                period reset or begun on October 1, 2024. By continuing to
                participate as a member of the AYANA Rewards program on or after
                the Effective Date, you are indicating your acceptance of the
                updated terms and conditions outlined below.
              </p>
              <p className="p--sm">
                AYANA Rewards is the loyalty program that rewards your
                discerning taste with unparalleled access to the AYANA
                experience. Unlock a world of personalized experiences crafted
                for the privileged few, and enjoy exclusive benefits like
                complimentary breakfast, late check-out, and preferential room
                upgrades. Immerse yourself in AYANA's legendary craftsmanship
                and artful service, and discover a deeper connection to our
                spellbinding destinations.
              </p>
              <p className="p--sm" style={{ marginBottom: "24px" }}>
                The following Terms & Conditions are designed to ensure the best
                experience for our valued members.
              </p>
              <Link
                id="general-terms-conditions"
                href="#general-terms-conditions"
                style={{
                  scrollMarginTop: "120px",
                  fontSize: "16px",
                  fontWeight: "700",
                  color: "black",
                  scrollBehavior: "smooth",
                }}>
                General Terms & Conditions:
              </Link>
              <ol style={{ marginTop: "7.5px", marginBottom: "24px" }}>
                <li>
                  Whenever there are changes in personal information, it is the
                  member's responsibility to update their membership record in
                  the AYANA Rewards Members' Portal or inform AYANA Rewards
                  Membership Services in writing.
                </li>
                <li>
                  AYANA Rewards reserves the right to amend the rules,
                  restrictions, program benefits, redemption rewards, and
                  special offers without notice. AYANA Rewards reserves the
                  right to terminate the AYANA Rewards program at any time.
                </li>
                <li>
                  AYANA Rewards makes no warranties or representations, whether
                  expressed or implied and expressly disclaims any liability
                  (including consequential damages) with respect to the quality
                  of goods or services claimed, redeemed and/or enjoyed through
                  the AYANA Rewards. All conditions, warranties or other terms
                  implied by law are excluded to the fullest extent permitted by
                  applicable laws and regulations. In the event any member
                  transfers the use of any Reward Certificate, this Clause shall
                  also apply to the transferee.
                </li>
                <li>
                  The Participating Hotels and/or AYANA Rewards reserve the
                  right to adjust the AYANA Rewards point values as and when
                  necessary to reflect changing market conditions.
                </li>
                <li>
                  Fraud or abuse concerning AYANA Rewards or reward usage is
                  subject to appropriate administrative and/or legal action by
                  the Participating Hotels and/or AYANA Rewards participating
                  partners.
                </li>
                <li>This Program is void where prohibited by law.</li>
                <li>
                  AYANA Rewards Loyalty Program is a program operated by the
                  Participating Hotels.
                </li>
                <li>
                  All interpretations of the Program Terms and Conditions shall
                  be at the sole discretion of AYANA Rewards. In the event of
                  any dispute, the member agrees to submit to the exclusive
                  jurisdiction of the courts of Indonesia.
                </li>
              </ol>
              <Link
                id="glossary"
                href="#glossary"
                style={{
                  scrollMarginTop: "140px",
                  fontSize: "16px",
                  fontWeight: "700",
                  color: "black",
                  scrollBehavior: "smooth",
                  marginTop: "20px",
                }}>
                1. Glossary
              </Link>
              <ol type="A" style={{ marginTop: "7.5px", marginBottom: "24px" }}>
                <li>
                  <span style={{ fontWeight: "700" }}>Active Status: </span>
                  an individual who has participated in website login activity,
                  completed a qualifying stay, made a qualifying spend, or
                  redeemed AYANA Rewards points within 24 months from the date
                  of their last recorded activity.
                </li>
                <li>
                  <span style={{ fontWeight: "700" }}>Reward Point: </span>
                  Points earned through eligible room rates and/or qualifying
                  charges at participating properties, including but not limited
                  to promotional offers and level-based incentives. These points
                  can be redeemed for exclusive experiences, subject to
                  availability and the program's terms and conditions.
                </li>
                <li>
                  <span style={{ fontWeight: "700" }}>Calendar Year: </span>
                  The period from 1 January to 31 December of each calendar year
                  from the date the Member is enrolled, upgraded, renewed, or
                  downgraded into the current membership level.
                </li>
                <li>
                  <span style={{ fontWeight: "700" }}>Level Point: </span>
                  Points earned that count towards qualification for the next
                  higher level or retention of the current level. Level point
                  excludes Bonus Points awarded as part of promotional offers or
                  membership status.
                </li>
                <li>
                  <span style={{ fontWeight: "700" }}>Eligible Night: </span>
                  The count of nights that contribute to a member's rewards
                  status, earned through eligible room rates and/or eligible
                  room reservation channels. The total number of nights stay is
                  determined by the number of rooms booked and the duration of
                  the stay.
                </li>
                <li>
                  <span style={{ fontWeight: "700" }}>
                    Eligible Room Rate:{" "}
                  </span>
                  Any published room rate of participating brands that can be
                  found on https://www.ayana.com/, https://rewards.ayana.com/,
                  https://www.delonixhotel.com/ or booked through Eligible
                  Booking Channels. Participating brands reserve the right, at
                  their sole discretion, to designate certain room rates as
                  eligible room rates from time to time.
                </li>
                <li>
                  <span style={{ fontWeight: "700" }}>
                    Eligible Booking Channel:{" "}
                  </span>
                  Eligible booking channels encompass Hotel Reservations,
                  https://www.ayana.com/, https://rewards.ayana.com/, and
                  https://www.delonixhotel.com/
                </li>
                <li>
                  <span style={{ fontWeight: "700" }}>
                    Eligible incidental Charge:{" "}
                  </span>
                  Refer to any spending at participating restaurants, spas, or
                  recreational facilities managed by the participating brands,
                  whether the member is classified as a hotel guest or a
                  non-hotel guest. Participating brands reserve the right, at
                  their sole discretion, to designate certain transactions as
                  eligible from time to time.
                </li>
                <li>
                  <span style={{ fontWeight: "700" }}>
                    Ineligible Booking Channel:{" "}
                  </span>
                  These include third-party platforms, online service providers,
                  online travel agents, wholesalers, organizers or planners, and
                  offline travel agents.
                </li>
                <li>
                  <span style={{ fontWeight: "700" }}>
                    Ineligible Room Rate:{" "}
                  </span>
                  These encompass rooms reserved through third-party sites such
                  as online travel agents, offline travel agents, and
                  wholesalers. This category also includes rates designated for
                  groups, weddings, events & conferences, airline crew, family &
                  friends, complimentary stays, free-night awards, room rates
                  billed to a Master Account, commissionable rates, and other
                  special rates not explicitly mentioned.
                </li>
                <li>
                  <span style={{ fontWeight: "700" }}>Brand: </span>
                  Any participating restaurants, spas, or partners (third-party)
                  that provide any services for AYANA Rewards
                </li>

                <li>
                  <span style={{ fontWeight: "700" }}>Pay with Point: </span>
                  Refer to a feature that enables members to book eligible rates
                  by utilizing AYANA reward points.
                </li>
                <li>
                  <span style={{ fontWeight: "700" }}>
                    Redemption Voucher:{" "}
                  </span>
                  Refer to any vouchers for free-night rewards, dining, spa, or
                  other categories that can be redeemed using the required
                  points.
                </li>
              </ol>
              <Link
                id="membership-eligibility"
                href="#membership-eligibility"
                style={{
                  scrollMarginTop: "140px",
                  fontSize: "16px",
                  fontWeight: "700",
                  color: "black",
                  scrollBehavior: "smooth",
                  marginTop: "20px",
                }}>
                2. Membership Eligibility
              </Link>
              <ol style={{ marginTop: "7.5px", marginBottom: "24px" }}>
                <li>
                  All individual guests are eligible for AYANA Rewards
                  membership. AYANA Rewards membership is not transferable. A
                  member must not maintain membership or earn points on multiple
                  accounts.
                </li>
                <li>
                  To be eligible for AYANA Rewards point issuance, AYANA Rewards
                  members must maintain active status.
                </li>
                <li>
                  Failure to maintain the member active status will result in
                  the deactivation of their membership, leading to the
                  forfeiture of any accumulated points, irrespective of their
                  expiry date.
                </li>
                <li>
                  AYANA Rewards reserves the right to refuse or terminate
                  Membership without a given reason or reject any application if
                  the required information is not supplied accurately and in
                  full.
                </li>
              </ol>
              <Link
                id="membership-levels"
                href="#membership-levels"
                style={{
                  scrollMarginTop: "140px",
                  fontSize: "16px",
                  fontWeight: "700",
                  color: "black",
                  scrollBehavior: "smooth",
                  marginTop: "20px",
                }}>
                3. Membership Level
              </Link>
              <ol style={{ marginTop: "7.5px", marginBottom: "24px" }}>
                <li>
                  AYANA Rewards offers three membership levels: Silver, Gold,
                  and Platinum, with Silver being the entry-level.
                </li>
                <li>
                  The table below outlines the required number of Eligible
                  Nights or Level Points to retain or upgrade membership levels:
                </li>
                <table>
                  <tr style={{ height: "25%", textAlign: "center" }}>
                    <th
                      style={{
                        padding: "10px",
                        border: "1px solid black",
                        fontWeight: "700",
                      }}>
                      Level
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        border: "1px solid black",
                        fontWeight: "700",
                      }}>
                      Eligible Night
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        verticalAlign: "middle",
                        textAlign: "center",
                        wordBreak: "break-all",
                        border: "1px solid black",
                        fontWeight: "700",
                      }}
                      rowspan="4">
                      OR
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        border: "1px solid black",
                        fontWeight: "700",
                      }}>
                      Level Points
                    </th>
                  </tr>
                  <tr style={{ height: "25%", textAlign: "center" }}>
                    <td
                      style={{
                        padding: "10px",
                        borderBottom: "1px solid black",
                      }}>
                      Silver
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        border: "1px solid black",
                      }}>
                      N/A
                    </td>
                    <td style={{ padding: "10px" }}>N/A</td>
                  </tr>
                  <tr style={{ height: "25%", textAlign: "center" }}>
                    <td style={{ padding: "10px", border: "1px solid black" }}>
                      Gold
                    </td>
                    <td style={{ padding: "10px", border: "1px solid black" }}>
                      20
                    </td>
                    <td style={{ padding: "10px", border: "1px solid black" }}>
                      8,000
                    </td>
                  </tr>
                  <tr style={{ height: "25%", textAlign: "center" }}>
                    <td style={{ padding: "10px", border: "1px solid black" }}>
                      Platinum
                    </td>
                    <td style={{ padding: "10px", border: "1px solid black" }}>
                      50
                    </td>
                    <td style={{ padding: "10px", border: "1px solid black" }}>
                      20,000
                    </td>
                  </tr>
                </table>
                <li>
                  Members must maintain the required level of points or eligible
                  nights until December 31 of the subsequent calendar year
                  following their last upgrade or downgrade. Failure to meet
                  these minimum requirements will result in a downgrade of
                  membership level.
                </li>
                <li>
                  AYANA Membership level remains valid until December 31 of the
                  following calendar year following the last upgrade or
                  downgrade or retention.
                </li>
                <li>
                  Eligible Nights or Level Points are for the sole purpose of
                  determining level status and such criteria may be changed from
                  time to time at the sole and absolute discretion of the AYANA
                  Rewards.
                </li>
                <li>
                  AYANA Rewards reserves the right to change qualifying criteria
                  for Membership upgrades and renewal at any time without prior
                  notice.
                </li>
              </ol>
              <Link
                id="member-benefits"
                href="#member-benefits"
                style={{
                  scrollMarginTop: "140px",
                  fontSize: "16px",
                  fontWeight: "700",
                  color: "black",
                  scrollBehavior: "smooth",
                  marginTop: "20px",
                }}>
                4.Member Benefits
              </Link>
              <h3>4.1 General Conditions</h3>
              <ol style={{ marginBottom: "24px" }}>
                <li>
                  Member who attain AYANA Rewards Gold or AYANA Rewards Platinum
                  status enjoy benefits related to both Hotel Stay and Non-Hotel
                  Stay, detailed in the table below:
                </li>
                <table>
                  <tr>
                    <th
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        fontWeight: "700",
                        textAlign: "center",
                      }}>
                      Benefit
                    </th>
                    <th
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        fontWeight: "700",
                        textAlign: "center",
                      }}>
                      Silver
                    </th>
                    <th
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        fontWeight: "700",
                        textAlign: "center",
                      }}>
                      Gold
                    </th>
                    <th
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        fontWeight: "700",
                        textAlign: "center",
                      }}>
                      Platinum
                    </th>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        wordBreak: "break-all",
                      }}>
                      Member exclusive room discount
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        fontWeight: "700",
                        textAlign: "center",
                        border: "1px solid black",
                      }}>
                      Eligible
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "700",
                      }}>
                      Eligible
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        fontWeight: "700",
                        textAlign: "center",
                      }}>
                      Eligible
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        wordBreak: "break-all",
                      }}>
                      Complimentary 1-tier room upgrade on the same booked room
                      class
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        textAlign: "center",
                        border: "1px solid black",
                      }}>
                      Ineligible
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "700",
                      }}>
                      Eligible
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        fontWeight: "700",
                        textAlign: "center",
                      }}>
                      Eligible
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        wordBreak: "break-all",
                      }}>
                      Bonus points on eligible spend
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        textAlign: "center",
                        border: "1px solid black",
                      }}>
                      Ineligible
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "700",
                      }}>
                      25%
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        fontWeight: "700",
                        textAlign: "center",
                      }}>
                      50%
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                      }}>
                      Early check-in
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        textAlign: "center",
                        border: "1px solid black",
                      }}>
                      Ineligible
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "700",
                      }}>
                      11:00
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "700",
                      }}>
                      11:00
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                      }}>
                      Late check-out
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        textAlign: "center",
                        border: "1px solid black",
                      }}>
                      Ineligible
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "700",
                      }}>
                      16:00
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "700",
                      }}>
                      18:00
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        wordBreak: "break-all",
                      }}>
                      Complimentary 1-time breakfast
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        textAlign: "center",
                        border: "1px solid black",
                      }}>
                      Ineligible
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                      }}>
                      Ineligible
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "700",
                      }}>
                      2 person
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        wordBreak: "break-all",
                      }}>
                      Welcome Gift
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        textAlign: "center",
                        border: "1px solid black",
                      }}>
                      Ineligible
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "700",
                      }}>
                      Eligible
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "700",
                      }}>
                      Eligible
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        wordBreak: "break-all",
                      }}>
                      Complimentary amenities when staying during birthday
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "1px solid black",
                      }}>
                      Eligible
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "700",
                      }}>
                      Eligible
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "700",
                      }}>
                      Eligible
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        wordBreak: "break-all",
                      }}>
                      Member exclusive discount at participating brands
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "1px solid black",
                      }}>
                      Eligible
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "700",
                      }}>
                      Eligible
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "700",
                      }}>
                      Eligible
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        wordBreak: "break-all",
                      }}>
                      Wellness program benefit
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "1px solid black",
                      }}>
                      Eligible
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "700",
                      }}>
                      Eligible
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "700",
                      }}>
                      Eligible
                    </td>
                  </tr>
                </table>
                <li>
                  Benefits for members are non-transferable and can only be
                  claimed by the members themselves.
                </li>
                <li>
                  AYANA Rewards Gold members qualify for 25% bonus points on
                  eligible spending, and AYANA Rewards Platinum members qualify
                  for 50% bonus points. However, transactions at Delonix
                  Residences Karawang are an exception, where AYANA Rewards Gold
                  members earn a 10% bonus for these transactions, while AYANA
                  Rewards Platinum members earn a 25% bonus.
                </li>
                <li>
                  AYANA Rewards Members are entitled to an exclusive room
                  discount at the participating brands
                  <ol type="a">
                    <li>
                      Members are entitled to a member-exclusive room discount
                      when booking through https://rewards.ayana.com/ and
                      signing in as a member.
                    </li>
                    <li>
                      Members are ineligible to claim the member-exclusive room
                      discount if they sign up after booking a room or if they
                      do not log in when booking a room at the participating
                      brands.
                    </li>
                  </ol>
                </li>
                <li>
                  AYANA Rewards Members' Benefits may be subject to availability
                  and blackout periods
                </li>
                <li>
                  AYANA Rewards reserves the right to amend or withdraw any
                  benefits at any time without prior notice to the member.
                </li>
              </ol>
              <h3>4.2 Member Benefits for Hotel Stay</h3>
              <ol>
                <li>
                  Benefits specific to hotel stays for AYANA Rewards members do
                  not extend to those residing at Residences or engaging in
                  long-term stays (exceeding or equal to 30 consecutive nights).
                </li>
                <li>
                  Members must occupy at least one room to be entitled to member
                  benefits related to Hotel Stay. If members are sharing a room
                  with another member, only the member registered for the main
                  reservation will be entitled to the membership benefits. If a
                  member reserves multiple rooms, all member benefits will apply
                  only to the room physically occupied by the members
                </li>
                <li>
                  If a member books a consecutive stay at the same location but
                  in different hotels or room types, the benefits related to
                  hotel stay, such as complimentary breakfast and welcome gift,
                  will only apply during the member's initial stay. regardless
                  whether the member checks out and immediately checks back in
                  again.
                </li>
                <li>
                  Member benefits related to Hotel Stay include:
                  <ol type="a">
                    <li>
                      Complimentary 1-tier room upgrade on the same room class
                      <ol type="i">
                        <li>
                          The complimentary 1-tier room upgrade applies to a
                          maximum limit of 1 room upgrade per member per stay.
                        </li>
                        <li>
                          This upgrade is valid within the same room category
                          and cannot be used for upgrades to different room
                          categories (e.g., from rooms to suites, suites to
                          villas, etc.).
                        </li>
                        <li>
                          This benefit does not apply to members who booked
                          residences, multiple-bedroom rooms, and villas.
                        </li>
                        <li>
                          Availability of the complimentary 1-tier room upgrade
                          is subject to the availability of the participating
                          hotel upon the member’s arrival.
                        </li>
                      </ol>
                      <li>
                        Early Check-in and/or Late Check-out
                        <ol type="i">
                          <li>
                            Members are entitled to early check-in and/or late
                            check-out, subject to availability at the
                            participating hotels upon member’s arrival.
                          </li>
                          <li>
                            Requests for early check-in at 11.00 are recommended
                            to be made at least 2 days prior to the member’s
                            arrival by providing the flight details and/or
                            estimated arrival time.
                          </li>
                          <li>
                            Requests for late check-out are recommended to be
                            made at the reception desk upon arrival.
                          </li>
                        </ol>
                        <li>
                          Complimentary amenities when staying during birthday
                          <br />
                          Members are entitled to receive a complimentary
                          birthday amenity when staying during their birthday.
                          The complimentary amenities may vary by participating
                          hotels.
                        </li>
                        <li>
                          Wellness Program Benefit
                          <ol type="i">
                            <li>
                              Members are eligible for the wellness program
                              benefit during their stay at the participating
                              hotels. This benefit applies only to the member
                              registered on the primary room reservation if
                              multiple members are staying in the same room.
                            </li>
                            <li>
                              The benefit may vary by participating hotels, with
                              details differing based on location.
                            </li>
                          </ol>
                        </li>
                        <li>
                          Complimentary 1-time Breakfast
                          <ol type="i">
                            <li>
                              Complimentary breakfast is provided to AYANA
                              Rewards Platinum members who book rooms with
                              breakfast excluded through the Eligible Booking
                              channels. This offer extends to the member plus
                              one accompanying individual (1 pax), regardless of
                              whether they are adults or children. Members must
                              present the voucher provided by the hotel to avail
                              themselves of the complimentary breakfast benefit.
                              In the event of loss, vouchers will not be
                              reissued.
                            </li>
                          </ol>
                        </li>
                        <li>
                          Welcome Gift
                          <ol type="i">
                            <li>
                              Welcome Gifts are provided to AYANA Rewards Gold
                              and Platinum members during each stay. The
                              specific gift offered may vary by participating
                              hotels, with details differing based on location.
                            </li>
                            <li>
                              Welcome Gifts are only available to members who
                              book their stay through the Eligible Booking
                              Channels.
                            </li>
                            <li>
                              Any unused Welcome Gifts before check-out will be
                              deemed to be forfeited
                            </li>
                            <li>
                              In the event of loss, vouchers will not be
                              reissued
                            </li>
                          </ol>
                        </li>
                      </li>
                    </li>
                  </ol>
                </li>
              </ol>
              <h3>4.3 Member Benefit - Non-Hotel Stay </h3>
              <div>
                Members of AYANA Rewards are entitled to exclusive benefits
                available at participating brands, as outlined below.
              </div>
              <ol type="1" style={{ marginBottom: "24px" }}>
                <li>
                  Member Exclusive Discount at Kawano Group Restaurants<br></br>
                  Terms and Conditions for Member Exclusive Discount at Kawano
                  Group Restaurants:
                  <ol type="a">
                    <li>
                      Current Participating Brands of Member Exclusive Discount
                      at Kawano Group Restaurants: Honzen, Sumizen, Kado, Aro
                      Aroy, Sakana, The Grill, Udonku, Riverside Restaurant.
                    </li>
                    <li>
                      Members must present their membership profile upon arrival
                      at the restaurants. Failure to do so may result in not
                      receiving the discount, and claims cannot be made after
                      payment at the participating restaurants.
                    </li>
                    <li>
                      The discount cannot be combined with any other discounts
                      or promotions.
                    </li>
                    <li>
                      There is no minimum or maximum spend required to enjoy
                      this discount.
                    </li>
                    <li>The discount excludes alcoholic beverages.</li>
                    <li>This benefit is not transferable.</li>
                    <li>
                      It applies to members whether or not they are staying at
                      the participating properties.
                    </li>
                  </ol>
                </li>
                <li>
                  Member Exclusive Discount at Liu Li Palace Seafood Restaurant
                  <br></br>
                  Terms and Conditions for Member Exclusive Discount at Liu Li
                  Palace Seafood Restaurant:
                  <ol type="a">
                    <li>
                      Members must present their membership profile upon arrival
                      at the restaurant. Failure to do so may result in not
                      receiving the discount, and claims cannot be made after
                      payment.
                    </li>
                    <li>
                      The discount cannot be combined with any other discounts
                      or promotions.
                    </li>
                    <li>
                      The discount is applicable only for purchases between IDR
                      300,000 and IDR 5,000,000 before tax and service charges.
                    </li>
                    <li>Applicable only to the à la carte menu.</li>
                    <li>
                      Blackout dates apply, including public holidays, festive
                      periods, and the Chinese New Year period.
                    </li>
                    <li>This benefit is not transferable.</li>
                    <li>
                      It applies to members whether or not they are staying at
                      the participating properties.
                    </li>
                  </ol>
                </li>
                <li>
                  Member Exclusive Discount at AYANA Bali Participating
                  Restaurants<br></br> Terms and Conditions for Member Exclusive
                  Discount at AYANA Bali Participating Restaurants:
                  <ol type="a">
                    <li>
                      Members must present their membership profile upon arrival
                      at the restaurants. Failure to do so may result in not
                      receiving the discount, and claims cannot be made after
                      payment at the participating restaurants.
                    </li>
                    <li>
                      The discount cannot be combined with any other discounts
                      or promotions.
                    </li>
                    <li>
                      Discounts are applicable only for individual diners and
                      are not applicable to group events.
                    </li>
                    <li>Blackout dates apply.</li>
                    <li>
                      The discount is applicable only to the à la carte menu.
                    </li>
                    <li>The discount does not apply to alcoholic beverages.</li>
                    <li>
                      Participating restaurants of exclusive discount at AYANA
                      Bali: Kisik Lounge & Seafood Restaurant, Kubu Beach Club,
                      Sami Sami, UNIQUE Rooftop Restaurant, Ah Yat Abalone
                      Seafood Restaurant, Scusa, DAVA Steak & Seafood, Martini
                      Bar, Luna Rooftop Bar, Karang, Kampoeng Bali, To’ge, Padi,
                      Damar Terrace, Forest Bar, Spa Cafe, ICE, and Pool Bar.
                    </li>
                    <li>
                      Outlets such as Rock Bar Bali; After Rock Bali; Saka Cafe;
                      Honzen; and Sumizen are not included in the member
                      exclusive discount at AYANA Bali Restaurants.
                    </li>
                    <li>This benefit is not transferable.</li>
                    <li>
                      It applies to members whether or not they are staying at
                      the participating properties.
                    </li>
                  </ol>
                </li>
                <li>
                  Member Exclusive Benefit at AYANA Spa at AYANA Bali <br></br>
                  Terms and Conditions for Member Exclusive Benefit at AYANA Spa
                  at AYANA Bali:
                  <ol type="a">
                    <li>
                      This offer is exclusively available to registered members
                      visiting AYANA Spa at AYANA Bali.
                    </li>
                    <li>
                      Members must purchase a minimum of a 60-minute treatment
                      at AYANA Spa to be eligible for this benefit.
                    </li>
                    <li>
                      It is applicable to both staying and non-staying guests.
                    </li>
                    <li>
                      This benefit cannot be combined with any other offers or
                      benefits.
                    </li>
                    <li>
                      It is valid for any published treatment at AYANA Spa.
                    </li>
                    <li>This benefit is not transferable.</li>
                    <li>
                      It applies to members whether or not they are staying at
                      the participating properties.
                    </li>
                  </ol>
                </li>
                <li>
                  Member Exclusive Discount at Delonix Hotel Karawang <br></br>
                  Terms and Conditions for Member Exclusive Discount at Delonix
                  Hotel Karawang:
                  <ol type="a">
                    <li>
                      Members must present their membership profile upon arrival
                      at the restaurants. Failure to do so may result in not
                      receiving the discount, and claims cannot be made after
                      payment.
                    </li>
                    <li>
                      The discount cannot be combined with any other discounts
                      or promotions.
                    </li>
                    <li>Blackout dates apply.</li>
                    <li>
                      Discounts are applicable only for individual diners and
                      are not applicable to group events.
                    </li>
                    <li>
                      The discount is applicable only to the à la carte menu.
                    </li>
                    <li>
                      Participating Restaurants for Exclusive Discount at
                      Delonix Hotel Karawang: Miami Restaurant.
                    </li>
                    <li>This benefit is not transferable.</li>
                    <li>
                      It applies to members whether or not they are staying at
                      the participating properties.
                    </li>
                  </ol>
                </li>
                <li>
                  Member Exclusive Discount at Riverside Golf Club <br></br>
                  Terms and Conditions for Member Exclusive Discount at
                  Riverside Golf Club:
                  <ol type="a">
                    <li>
                      Members must present their membership profile upon arrival
                      at the facilities. Failure to do so may result in not
                      receiving the discount, and claims cannot be made after
                      payment.
                    </li>
                    <li>
                      The discount cannot be combined with any other ongoing
                      program, discount, or promotion.
                    </li>
                    <li>
                      Discounts are applicable only for individual players and
                      are not applicable to group events.
                    </li>
                    <li>This benefit is not transferable.</li>
                  </ol>
                </li>
                <li>
                  Member Exclusive Discount at AYANA Midplaza Jakarta <br></br>
                  Terms and Conditions for Member Exclusive Discount at AYANA
                  Spa at AYANA Midplaza Jakarta:
                  <ol type="a">
                    <li>
                      This offer is exclusively available to registered members
                      visiting AYANA Spa at AYANA Midplaza Jakarta
                    </li>
                    <li>
                      It applies to members whether or not they are staying at
                      the participating properties.
                    </li>
                    <li>
                      This promotion cannot be combined with any other offers or
                      benefits.
                    </li>
                    <li>
                      It is valid for any published treatment at AYANA Spa.
                    </li>
                    <li>This benefit is not transferable.</li>
                  </ol>
                </li>
                <li>
                  Member Exclusive Discount at AYANA Komodo Waecicu Beach
                  <br></br> Terms and Conditions for Member Exclusive Discount
                  at AYANA Spa at AYANA Komodo Waecicu Beach:
                  <ol type="a">
                    <li>
                      This offer is exclusively available to registered members
                      visiting AYANA Spa.
                    </li>
                    <li>
                      It applies to members whether or not they are staying at
                      the participating properties.
                    </li>
                    <li>
                      This promotion cannot be combined with any other offers or
                      benefits.
                    </li>
                    <li>
                      It is valid for any published treatment at AYANA Spa.
                    </li>
                    <li>This benefit is not transferable.</li>
                  </ol>
                </li>
              </ol>
              <Link
                id="points-earning"
                href="#points-earning"
                style={{
                  scrollMarginTop: "140px",
                  fontSize: "16px",
                  fontWeight: "700",
                  color: "black",
                  scrollBehavior: "smooth",
                  marginTop: "20px",
                }}>
                5. Points Earning
              </Link>
              <h3>5.1 General Conditions</h3>
              <ol style={{ marginBottom: "24px" }}>
                <li>
                  Members will earn 1 (one) AYANA Rewards point for every IDR
                  10,000 spent at participating brands, with the exception of
                  Delonix Hotel Karawang, Delonix Residences Karawang, and
                  Riverside Golf Club. At these locations, members will earn 1
                  (one) AYANA Rewards point for every IDR 15,000 spent on
                  eligible charges, excluding tax and service charges.
                  Participating brands include AYANA Midplaza Jakarta, AYANA
                  Komodo Waecicu Beach, AYANA Segara Bali, AYANA Villas Bali,
                  AYANA Resort Bali, and RIMBA by AYANA Bali.
                </li>
                <li>
                  Earnings of AYANA Rewards points and redemption of rewards are
                  subject to all applicable laws and regulations.
                </li>
                <li>
                  Points will be rounded down to the nearest whole number of
                  points.
                </li>
                <li>
                  Taxes, service charges, gratuities, and other charges are not
                  categorized as Eligible Incidental Charges, thus, not eligible
                  for earning AYANA Rewards points.
                </li>
                <li>
                  The validity period for accumulated points is 24-months from
                  the date of the last Active Status.
                </li>
                <li>
                  Members must inform the AYANA Rewards Membership Services team
                  within 60 days of their check-out date/transaction date if
                  they notice any missing credits in their AYANA Rewards member
                  account. Failure to notify the Membership Services team within
                  this timeframe will result in the non-crediting of relevant
                  AYANA Rewards points.
                </li>
                <li>
                  Members can verify their points status 7 working days after
                  checking out / transaction. Any claims for missing AYANA
                  Rewards points can be submitted via email to the AYANA Rewards
                  Membership Services team at info@ayanarewards.com or through
                  the "Missing Points Form" on their membership account.
                </li>
                <li>
                  AYANA Rewards reserves the right to deduct points from a
                  member's account balance if the points have been erroneously
                  credited to the account.
                </li>
              </ol>
              <h3>5.2 Points Earning – Hotels & Resorts</h3>
              <ol>
                <li>
                  AYANA Rewards points are reserved for members who adhere to
                  specific criteria during their stay at Eligible Room Rates.
                  These conditions include:
                  <ol type="a">
                    <li>
                      The member must be registered as a staying guest in the
                      reservation.
                    </li>
                    <li>
                      AYANA Rewards Points can be earned for up to five rooms
                      reserved for the same date at a participating hotel.
                    </li>
                    <li>
                      All five rooms must be registered under the same
                      membership number or email address as the member.
                    </li>
                  </ol>
                </li>
                <li>
                  AYANA Rewards members who solely act as the booker of the room
                  but do not personally check-in and stay at the participating
                  hotels are not eligible to earn AYANA Rewards points for the
                  room and incidental charges incurred during the stay.
                </li>
                <li>
                  At all participating hotels, regardless of the room rate,
                  members booking through any Ineligible Booking Channels are
                  not eligible to receive eligible nights or points for their
                  rooms. However, members can still earn AYANA Rewards points on
                  Eligible Incidental Charges that the member settled to the
                  room.
                </li>
                <li>
                  If members do not settle the Eligible Incidental Charges to
                  their room, members are required to inform the hotel staff at
                  participating brands that they would like to earn AYANA
                  Rewards points from the incidentals.
                </li>
                <li>
                  Guests will be eligible to earn AYANA Rewards points after
                  they become AYANA Rewards members and maintain their Active
                  Status. Points will be credited to members’ accounts up to 7
                  (seven) working days after check-out.
                </li>
                <li>
                  Eligible Charges for AYANA Rewards points earning:
                  <ol type="a">
                    <li>
                      AYANA Midplaza Jakarta
                      <ol type="i">
                        <li>
                          Room rates include Early Check-in, Late Check-out, and
                          Extra Person charges but exclude Day Use.
                        </li>
                        <li>In-room service (exclude Minibar)</li>
                        <li>
                          Restaurant expenditures managed by the hotel
                          (excluding third-party restaurants (e.g., Honzen,
                          KADO))
                        </li>
                        <li>Selected recreation expenditures</li>
                        <li>Spa expenditures</li>
                      </ol>
                      <li>
                        Delonix Hotel Karawang
                        <ol type="i">
                          <li>
                            Room rates include Early Check-in, Late Check-out,
                            and Extra Person charges but exclude Day Use.
                          </li>
                          <li>In-room service (exclude Minibar)</li>
                          <li>
                            Restaurant expenditures managed by the hotel
                            (excluding third-party restaurants (e.g., Sakana
                            Restaurant))
                          </li>
                          <li>Selected recreation expenditures</li>
                          <li>Laundry and Transportation</li>
                        </ol>
                        <li>
                          AYANA Bali (AYANA Villas Bali, AYANA Segara Bali,
                          AYANA Resort Bali, and RIMBA by AYANA Bali)
                          <ol type="i">
                            <li>
                              Room rates include Early Check-in, Late Check-out,
                              and Extra Person charges but exclude Day Use.
                            </li>
                            <li>In-room service (exclude Minibar)</li>
                            <li>
                              Restaurant expenditures managed by the hotel
                              (excluding third-party restaurants (e.g., Honzen,
                              Sumizen))
                            </li>
                            <li>Selected recreation expenditures</li>
                            <li>Spa expenditures</li>
                          </ol>
                          <li>
                            AYANA Komodo Waecicu Beach
                            <ol type="i">
                              <li>
                                Room rates include Early Check-in, Late
                                Check-out, and Extra Person charges but exclude
                                Day Use.
                              </li>
                              <li>In-room service (exclude Minibar)</li>
                              <li>
                                Restaurant expenditures managed by the hotel
                                (excluding third-party restaurants (e.g.,
                                Honzen))
                              </li>
                              <li>Selected recreation expenditures</li>
                              <li>Spa expenditures</li>
                            </ol>
                          </li>
                          <li>
                            AYANA Lako di’a
                            <ol type="i">
                              <li>
                                Program package charges and overnight stay
                                charter through individual direct booking only,
                                with exceptions of special rates, and any other
                                consumption including alcohol consumption
                              </li>
                            </ol>
                          </li>
                        </li>
                      </li>
                    </li>
                  </ol>
                </li>
              </ol>
              <h3>5.3 Points Earning – Activity</h3>
              <p className="p--sm">
                AYANA Rewards Points earn solely for players who both pay for
                and participate in activities at the Riverside Golf Club. Guests
                qualify for point accumulation upon enrolling in the AYANA
                Rewards program, with AYANA Rewards points credited to their
                accounts within 7 business days post-transaction. AYANA Rewards
                points at Riverside Golf Club are exclusively attainable for
                individual players. Events held at the club do not qualify for
                Points Earning.
              </p>
              <ol>
                <ol>
                  <li>
                    Eligible Charges for AYANA Rewards Points Earning:
                    <ol type="a">
                      <li>Green Fees</li>
                    </ol>
                  </li>
                  <li>
                    Ineligible Charges for AYANA Rewards Points Earning:
                    <ol type="a">
                      <li>Service fees</li>
                      <li>Restaurant expenditures</li>
                      <li>Spa expenditures</li>
                      <li>Retail purchases</li>
                      <li>Riverside Golf Club Membership Purchase</li>
                    </ol>
                  </li>
                </ol>
              </ol>
              <p className="p--sm" style={{ marginBottom: "24px" }}>
                Members who purchase an annual membership at Riverside Golf Club
                are entitled to a direct upgrade to AYANA Rewards Gold, while
                those opting for long-term memberships receive a direct upgrade
                to AYANA Rewards Platinum.
              </p>
              <Link
                id="ayana-rewards-redemption"
                href="#ayana-rewards-redemption"
                style={{
                  scrollMarginTop: "140px",
                  fontSize: "16px",
                  fontWeight: "700",
                  color: "black",
                  scrollBehavior: "smooth",
                  marginTop: "20px",
                }}>
                6. AYANA Rewards Redemption
              </Link>
              <h3>6.1 General Conditions</h3>
              <ol style={{ marginBottom: "24px" }}>
                <li>
                  The safekeep of the Reward Certificates is the sole
                  responsibility of the member. There shall be no replacement of
                  reward certificates.
                </li>
                <li>
                  Benefits and rewards received under AYANA Rewards may be
                  subject to taxes. Such taxes are the sole responsibility of
                  the member who is the recipient of the said benefit or reward.
                </li>
              </ol>
              <h3>6.2 Redeeming for Redemption Vouchers</h3>
              <ol>
                <li>
                  Members may redeem their AYANA Rewards points for redemption
                  vouchers at rewards.ayana.com under the Members’ Portal –
                  Redeem Section. In addition to Members' user IDs and
                  passwords, AYANA Rewards reserves the right to seek other
                  personal information for verification purposes.
                </li>
                <li>
                  Redemption vouchers must be redeemed prior to utilizing the
                  associated products or services. Members must present either
                  the printed or e-voucher sent to their email address as proof
                  of redemption.
                </li>
                <li>
                  If a redemption voucher is used by someone other than the
                  member, the member must inform AYANA Rewards Membership
                  customer service via written email beforehand. Failure to
                  provide this information may result in the hotel refusing to
                  accept the redemption voucher.
                </li>
                <li>
                  Members are responsible for referring to the Terms and
                  Conditions and procedures outlined for each reward before
                  claiming it.
                </li>
                <li>
                  To utilize a redemption voucher, members must make a
                  pre-booking by sending an email to info@ayanarewards.com,
                  specifying their preferred utilization date. Additional
                  surcharges may apply during high/peak season periods.
                </li>
                <li>
                  All redemption vouchers are non-refundable and
                  non-cancellable. Member’s AYANA Rewards points will be
                  deducted at the time of redemption and will follow the
                  redemption policy.
                </li>
                <li>
                  Redemption vouchers will remain valid for up to 6 months (180
                  days) from the redemption date. However, at a certain period,
                  the redemption vouchers may have a validation period of less
                  than 6 months.
                </li>
                <li>
                  The expiration period indicates the latest date by which a
                  member can utilize the voucher. For free-night vouchers, this
                  refers to the check-out date of the stay. For non-free-night
                  vouchers, it signifies the last date by which the member can
                  utilize the service.
                </li>
                <li>
                  Unused redemption vouchers cannot be returned for credit to
                  the Members' accounts or exchanged for cash or extended.
                </li>
                <li>
                  AYANA Rewards is not responsible for Redemption Partners'
                  withdrawal from the program or Partners' rewards cancellation,
                  which may affect the redemption offered.
                </li>
              </ol>
              <h3>6.3 Pay with Points</h3>
              <ol style={{ marginBottom: "24px" }}>
                <li>
                  Members can use the AYANA Rewards points earned to book rooms
                  at participating brands using the Pay with Points feature
                  (cash & points).
                </li>

                <li>
                  A minimum of 500 AYANA Rewards points is required to activate
                  the Pay with Points feature on the membership account.
                </li>
                <li>
                  Members have the option to use their full points or a
                  combination of points and other available payment methods.
                </li>
                <li>
                  Pay with Points is only applicable to room types and rates
                  marked as "Eligible to use AYANA Rewards Points".
                </li>
                <li>
                  Pay with Points is available exclusively for members who book
                  through https://www.ayana.com/, https://rewards.ayana.com/, or
                  https://www.delonixhotel.com/. This feature is not available
                  for bookings made through offline Eligible Booking Channels.
                </li>
                <li>
                  Pay with Points is not applicable to existing bookings that
                  members booked without utilizing the feature.
                </li>
                <li>
                  Members may earn points from room rates and incidental charges
                  during their stay, with points automatically issued within 7
                  working days after check-out.
                </li>
                <li>
                  The Pay with Points feature may not apply to room offers or
                  promotions unless explicitly permitted by participating
                  hotels.
                </li>
                <li>
                  Members using Pay with Points must adhere to the cancellation
                  policy deadline set by each Participating brand, as outlined
                  within the specific rate details selected at the time of
                  booking. Failure to do so may result in redeemed points not
                  being refunded.
                </li>
                <li>
                  Points used for payment will be refunded to the membership
                  account in the form of points. For payments made using other
                  available methods on the booking engine, refunds will be
                  issued in the same payment method selected during the booking.
                </li>
              </ol>
              <Link
                id="data-privacy"
                href="#data-privacy"
                style={{
                  scrollMarginTop: "140px",
                  fontSize: "16px",
                  fontWeight: "700",
                  color: "black",
                  scrollBehavior: "smooth",
                  marginTop: "20px",
                }}>
                7. Data Privacy
              </Link>
              <ol style={{ marginTop: "7.5px", marginBottom: "24px" }}>
                <li>
                  <b>Information Collection:</b> AYANA Rewards collects personal
                  information necessary for the purposes of membership
                  management and enhancing the member experience. This
                  information may include, but is not limited to, name, gender,
                  email address, nationality, date of birth, preferred language,
                  phone number, mailing address, country of residence,
                  membership level, points activity, stay history, and activity
                  on the AYANA Rewards website to facilitate personalized
                  experiences.
                </li>
                <li>
                  <b>Social Media Account Linking:</b> If you elect to link your
                  social media accounts, such as your Google account, to your
                  AYANA Rewards membership, we may collect specific information
                  from your social media profile, including your name and email
                  address. This enables seamless registration and login, as well
                  as enhanced personalization of your experience with AYANA
                  Rewards. We only access information that is directly relevant
                  and necessary for the purposes outlined in this policy, in
                  compliance with applicable data protection laws.
                </li>
                <li>
                  <b>Use of Information:</b> The personal information collected
                  is used to effectively manage the AYANA Rewards Program and
                  deliver associated benefits. We use contact details to
                  communicate program updates and special offers. Furthermore,
                  we analyze data to personalize your AYANA experience and
                  improve our services in accordance with your preferences. All
                  data processing is conducted in compliance with applicable
                  privacy laws and regulations.
                </li>
                <li>
                  <b>Information Sharing:</b> AYANA may share your personal
                  information with trusted third-party service providers who
                  assist with the operation of the AYANA Rewards Program. These
                  service providers are contractually obligated to adhere to
                  strict data security and privacy standards to protect your
                  personal information and ensure compliance with relevant legal
                  requirements.
                </li>
                <li>
                  <b>Data Subject Rights:</b> You have the right to request
                  access to, rectify, or delete your personal information
                  related to the AYANA Rewards Program, subject to certain legal
                  exceptions. You also have the right to object to or restrict
                  processing of your information, and to opt-out of marketing
                  communications at any time. Detailed instructions on how to
                  exercise these rights are provided within the Program’s user
                  settings, and we are committed to ensuring that these
                  processes are straightforward and accessible.
                </li>
                <br></br>
                By participating in the AYANA Rewards Program, you acknowledge
                that your personal information will be processed as described in
                this policy. If you have any questions or concerns about our
                data privacy practices, please contact us at
                info@ayanarewards.com.
              </ol>
            </div>
          </div>
        </Layout>
      );
    } else {
      return (
        <Layout location={this.props.location}>
          <SEO
            title="Terms and Conditions "
            keyword="Terms and Conditions"
            decription="Terms and Conditions"
          />

          <div className="terms">
            <div className="container container--1000">
              <h1 className="text--center">Terms and Conditions</h1>
              <p className="p--sm">
                AYANA Rewards is a frequent patronage program designed to reward
                guests for staying in AYANA Komodo Resort, AYANA Lako Di’a, The
                Villas at AYANA Resort, BALI, AYANA Resort and Spa, BALI, RIMBA
                Jimbaran BALI, AYANA Midplaza JAKARTA and Delonix Hotel
                Karawang. The following Terms & Conditions are intended to
                protect members of AYANA Rewards. Should you require further
                clarification, please do not hesitate to email our Membership
                Services at{" "}
                <a href="mailto:info@ayanarewards.com">info@ayanarewards.com</a>{" "}
                or log on to{" "}
                <a href="https://www.ayanarewards.com/">ayanarewards.com.</a>
              </p>
              <h3>Membership Eligibility</h3>
              <ol>
                <li>
                  All individual guests are eligible for AYANA Rewards
                  membership. AYANA Rewards membership is not
                  transferable.&nbsp;Members must not maintain membership in or
                  earn points on multiple accounts.
                </li>
                {/* <li>
                    Membership is valid within two (2) years.&nbsp;Members account will be deactivated and all accumulated points subject to forfeiture if there&rsquo;s no activity along these years.</li> */}
                <li>
                  Member may reach higher membership level when their points
                  qualify. Membership level is valid for one (1 year). Member
                  that have collected 5,000 points a year will be upgraded to
                  Gold and 1,500 points required a year to maintain Gold
                  status.&nbsp;Member that have collected 10,000 points a year
                  will be upgraded to Platinum and 5,000 points required a year
                  to maintain Platinum status. Failure of collecting the points
                  stated will result in downgarde of membership level.
                </li>
                <li>
                  AYANA Rewards reserves the right to refuse/terminate
                  Membership without a given reason or reject any application if
                  the required information is not supplied accurately and in
                  full.
                </li>
              </ol>
              <h3>Earning Points</h3>
              <ol>
                <li>
                  Member will earn 1 AYANA Rewards points for every IDR 10,000
                  spent at participating brands for every eligible charge,
                  before tax and service. Delonix Karawang Hotel and Residence
                  will get 1 AYANA Rewards points for every IDR 15,000 spent,
                  before tax and service. Points will be rounded down to the
                  nearest whole number of points.
                </li>
                <li>
                  AYANA Rewards point is only eligible for Member that check in,
                  stay, and pay for their room charges at the eligible
                  rates.&nbsp;Member may earn points for up to five rooms with
                  the same stay dates at a participating property when (1) each
                  room is paid by Member and all charges are settled at check
                  out (2) one room is occupied by Member.
                </li>
                <li>
                  AYANA Rewards Points are valid for ten (10) years or 120
                  months from date of issuance.
                </li>
                <li>
                  For all hotels, regardless of the rate booked, members who
                  book through any third party sites (except GDS) are ineligible
                  to receive points for their room. All members may still earn
                  points on all eligible incidentals charged to their room.
                </li>
                <li>
                  Guest will be eligible to earn points after they become AYANA
                  Rewards member. Points will be credited to member&rsquo;s
                  account up to 7 working days after check out.
                </li>
                <li>
                  Eligible charges:
                  <ul>
                    <li>
                      AYANA Midplaza JAKARTA
                      <ul>
                        <li>
                          Most room rates: the program allows members to earn
                          points on most room rates, with the only exceptions
                          being the non-applicable rates as employee rates,
                          commisionable rates, wholesaler rates, free nights
                          awards, room rate billed to a Master Account, crew
                          room rates, cash &amp; points rates, and group rates.
                          Members who are paying a non-applicable room rate may
                          still earn points on eligible incidental charges.
                          Properties are responsible for flagging these rates as
                          non-pointable in their property systems.
                        </li>
                        <li>
                          Food &amp; Beverage charges (excluding HonZen and KaDO
                          Restaurant)
                        </li>
                        <li>
                          No-show charges, early departure, and cancellation
                          fees
                        </li>
                      </ul>
                    </li>
                    <li>
                      Delonix Hotel Karawang
                      <ul>
                        <li>
                          All charges are eligible (excluding Sakana Restaurant
                          charges)
                        </li>
                      </ul>
                    </li>
                    <li>
                      AYANA Bali Hotels and AYANA Komodo Resort
                      <ul>
                        <li>
                          Room rates through individual direct booking only,
                          with exceptions of special rates
                        </li>
                      </ul>
                    </li>
                    <li>
                      AYANA Lako Di'a
                      <ul>
                        <li>
                          Program package charges and overnight stay charter
                          through individual direct booking only, with
                          exceptions of special rates, and any other consumption
                          including alcohol consumption
                        </li>
                      </ul>
                    </li>
                  </ul>
                  ** Service charges, taxes, and other incidental charges are
                  not eligible for AYANA Rewards points.
                </li>
                <li>
                  Members may log in to the AYANA Rewards members&#39; portal -
                  ayanarewards.com - to check their AYANA Rewards point balance
                  and transaction activities or email the AYANA Rewards
                  Membership Services Manager at info@ayanarewards.com to get an
                  update.
                </li>
                <li>
                  Members who are missing credit from their AYANA Rewards member
                  account must notify the AYANA Rewards Membership Services
                  Manager within 30 days of the check-out date. Failure to
                  notify the membership services team, will result to non-credit
                  of the relevant AYANA Rewards points. Members can verify their
                  points one 7 working days after they have checked out. Any
                  claims for missing AYANA Rewards points can be emailed to
                  AYANA Rewards Membership Services via email at
                  info@ayanarewards.com.
                </li>
                <li>
                  AYANA Rewards reserves the right to deduct points from a
                  member&#39;s account balance if the points have been
                  erroneously credited in the account.
                </li>
              </ol>
              <h3>Claiming the Reward</h3>
              <ol>
                <li>
                  Members may redeem their AYANA Rewards points for rewards at
                  ayanarewards.com under Members&#39; Portal &ndash; Rewards /
                  Redemption section. In addition to Members&#39; user IDs and
                  passwords, AYANA Rewards reserves the right to seek other
                  personal information for verification purposes.
                </li>
                <li>
                  Minimum AYANA Rewards point levels apply to each reward.
                  Please refer to the Rewards Chart of the website for
                  redemption requirements.
                </li>
                <li>
                  It is member&rsquo;s obligation to always refer to Term and
                  Conditions stated in each reward before claiming the reward.
                </li>
                <li>
                  For night stay rewards, pre-booking is required by sending an
                  email to info@ayanarewards.com&nbsp;stating the preferred stay
                  date. Please note that there might be an additional surcharge
                  applied during high season period.
                </li>
                <li>
                  All rewards are non-refundable and non-cancellable.
                  Member&rsquo;s points will be deducted at time of redemption
                  and will follow the redemption policy.
                </li>
                <li>
                  Reward will valid for up to 6 months since redeemed date.
                  Unused reward cannot be returned for credit to the
                  Members&#39; accounts or exchanged for cash.
                </li>
                <li>
                  AYANA Rewards is not responsible for Redemption Partners&#39;
                  withdrawal from the program or Partners&#39; rewards
                  cancellation, which may affect the rewards offered.
                </li>
              </ol>
              <h3>General Rules</h3>
              <ol>
                <li>
                  Whenever there are changes of personal information, it is the
                  Member&#39;s responsibility to update their membership record
                  in the AYANA Rewards Members&#39; Portal or inform AYANA
                  Rewards Membership Services in writing.
                </li>
                <li>
                  The safe keep of the Reward Certificates is the sole
                  responsibility of the member. There shall be no replacement of
                  reward certificates.
                </li>
                <li>
                  The AYANA Rewards Membership Services Office reserves the
                  right to correct any Member statements or AYANA Rewards Point
                  accruals or balances that are incorrect due to processing
                  errors.
                </li>
                <li>
                  AYANA Rewards reserves the right to amend the rules,
                  restrictions, program benefits, and special offers without
                  notice. AYANA Rewards reserves the right to terminate the
                  AYANA Rewards program at any time.
                </li>
                <li>
                  Earnings of AYANA Rewards Points and redemption of Rewards are
                  subject to all applicable laws and regulations.
                </li>
                <li>
                  Benefits and Rewards received under AYANA Rewards may be
                  subject to taxes. Such taxes are the sole responsibility of
                  the Member who is the recipient of the said benefit or Reward.
                </li>
                <li>
                  AYANA Rewards makes no warranties or representations, whether
                  expressed or implied, and expressly disclaim any and all
                  liability (including consequential damages) with respect to
                  the quality of goods or services claimed, redeemed and/or
                  enjoyed through the AYANA Rewards. All conditions, warranties
                  or other terms implied by law are excluded to the fullest
                  extent permitted by applicable laws and regulations. In the
                  event any Member transfers the use of any Reward Certificate,
                  this Clause shall also apply to the transferee.
                </li>
                <li>
                  The Participating Hotels and/or AYANA Rewards reserve the
                  right to adjust the AYANA Rewards Point values as and when
                  necessary to reflect changing market conditions.
                </li>
                <li>
                  Fraud or abuse concerning AYANA Rewards or Reward usage is
                  subject to appropriate administrative and/or legal action by
                  the Participating Hotels and/or AYANA Rewards participating
                  partners.
                </li>
                <li>This Program is void where prohibited by law.</li>
                <li>
                  AYANA Rewards Loyalty Program is a program operated by the
                  Participating Hotels. Service providers may also have access
                  to Members&#39; records.
                </li>
                <li>
                  All interpretations of the Program Terms and Conditions shall
                  be at the sole discretion of AYANA Rewards. In the event of
                  any dispute, the Member agrees to submit to the exclusive
                  jurisdiction of the courts of Indonesia.
                </li>
              </ol>
            </div>
          </div>
        </Layout>
      );
    }
  }
}
